import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { linkResolver } from './src/utils/linkResolver'

// PrismicRichText serializer
const components = {
  paragraph: ({ node, children }) => {
    //console.log(node)
    return (
      <>
      {node.spans ? (
       <>
          {node.spans[0]?.data?.label === 'center-align' 
          ? (<p style={{textAlign: 'center'}}>
          {children}
            </p>) 
          : node.spans[0]?.data?.label === 'right-align' ? 
          (<p className='right-align'>
            {children}
            </p>) : <p>{children}</p>  }
       </>
      ): 
      (<p>
        {children}
      </p>)}
      </>
    )
  },

}

export const wrapRootElement = ({ element }) => {
  return (  
    <PrismicProvider
      linkResolver={linkResolver}
      richTextComponents={components}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      {element}
    </PrismicProvider>
)
}