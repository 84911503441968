exports.linkResolver = (doc) => {
    //console.log(doc);
    if (doc.type === 'project_page') {
        return `/projects/${doc.uid}`;
    }
    
    if (doc.type === 'page') {
        return `/${doc.uid}`;
    }

    if (doc.type === 'projects_page') {
        return `/${doc.uid}`;
    }

    if (doc.type === 'contact_page') {
        return `/${doc.uid}`;
    }
 
    return '/';
}